import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { FaShoppingCart } from "react-icons/fa";
import { toast } from "react-toastify";
import greyuser from "../../assets/images/usre-grey.jpg"

// Initialize Stripe outside of the component
const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY);

// Styled Card component
const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  borderRadius: "18px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  position: "relative",
}));

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: "10px",
}));

const StyledField = styled(Typography)(({ theme }) => ({
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
  fontSize: "14px",
}));

const CartIconWrapper = styled("div")(() => ({
  position: "fixed",
  top: "80px",
  right: "20px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#0A58CA",
  fontWeight: "600",
  fontSize: "16px",
  background: "#fff",
  padding: "8px 12px",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
}));
const maskData = (data) => {
  return data?.length > 1 ? data[0] + "*".repeat(data?.length - 1) : data;
};

const IntentBodyUser = () => {
  const [datas, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [revealedLeadIds, setRevealedLeadIds] = useState(new Set());
  const [cart, setCart] = useState([]); // Cart state to hold items
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page = 1) => {
    try {
      const response = await axios.get(`/intent?page=${page}`);
      const updatedData = response.data?.intent_list.map((item) => ({
        ...item,
        price: 1,
      }));
      setData(updatedData);
      setTotalPages(response.data.totalPages); // Set the total pages for pagination
      setCurrentPage(response.data.currentPage); // Update the current page
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Fetch data on page change

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update current page on pagination button click
  };
  const handleAddToCart = (dataId) => {
    const isAlreadyInCart = cart.some((item) => item._id === dataId);
    if (isAlreadyInCart) {
      toast.warn("Item already in cart.");
    } else {
      const selectedItem = datas.find((item) => item._id === dataId);
      setCart((prevCart) => [...prevCart, selectedItem]);
    }
  };

  const handleRemoveFromCart = (dataId) => {
    const updatedCart = cart.filter((item) => item._id !== dataId);
    setCart(updatedCart);
  };

  const handleBuyLead = async () => {
    if (cart.length === 0) {
      toast.warn("Your cart is empty!");

      return;
    }

    const leadIds = cart.map((item) => item._id);
    const totalAmount = cart.length * 1; // Each item costs $1, so multiply cart length by 1

    try {
      const response = await axios.post("/payment/buyleads", {
        userId: user._id,
        leadIds,
        totalAmount,
      });

      if (response.data.id) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: response.data.id,
        });

        if (!error) {
          try {
            const confirmResponse = await axios.post(
              "/payment/confirmLeadPurchase",
              {
                userId: user._id,
              }
            );

            console.log("Payment confirmed:", confirmResponse.data);
            alert("Lead purchase successful!");
            setCart([]);
            fetchData();
          } catch (confirmError) {
            console.error("Error confirming lead purchase:", confirmError);
            alert("An error occurred while confirming the purchase.");
          }
        } else {
          console.error("Stripe checkout error:", error);
        }
      } else {
        console.error("Stripe session creation failed:", response.data);
      }
    } catch (error) {
      console.error("Error during lead purchase:", error);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <CartIconWrapper>
        <FaShoppingCart size={18} />
        {cart.length}
      </CartIconWrapper>

      <Grid
        container
        spacing={3}
        style={{ boxSizing: "border-box", marginBottom: "1rem" }}
      >
        {/* Cart Icon and Count */}

        {datas.map((data) => (
          <Grid item xs={12} sm={6} md={4} key={data._id}>
            <StyledCard>
              {/* Header */}
              <StyledRow>
                <img
                  src={
                    data?.logo
                      ? `${process.env.REACT_APP_API_URL}/${data.logo}`
                      : `${greyuser}`
                  }
                  alt={data?.companyName || "No Company Name"}
                  style={{ width: "50px", height: "50px", borderRadius: "5px" }}
                />
                <StyledField variant="h6">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {" "}
                    <div
                      style={{
                        color: "#0A58CA",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {data?.category || " "}
                    </div>
                    <div> {data?.companyName || "No Company Name"}</div>{" "}
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 1: Name & Designation */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Name</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.customerName
                        : maskData(data?.customerName) || "No Customer Name"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> Designation</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.designation
                        : maskData(data?.designation) || "No Designation"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 2: Mobile & Email */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Mobile</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.mobileNumber
                        : maskData(data?.mobileNumber) || "No Mobile"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Email</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.email
                        : maskData(data?.email) || "No Email"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 3: City & State */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> City</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.city
                        : maskData(data?.city) || "No City"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div> State</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.state
                        : maskData(data?.state) || "No State"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>

              {/* Buttons */}
              <div style={{ paddingTop: "1rem" }}>
                <StyledRow>
                  {cart.some((item) => item._id === data._id) ? (
                    <Button
                      onClick={() => handleRemoveFromCart(data._id)}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        padding: "6px 18px",
                        backgroundColor: "#DC3545",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontFamily: "Poppins",
                      }}
                      variant="contained"
                    >
                      Remove Cart
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleAddToCart(data._id)}
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        padding: "6px 18px",
                        backgroundColor: "#0A58CA",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        fontFamily: "Poppins",
                      }}
                      variant="contained"
                    >
                      Add to Cart
                    </Button>
                  )}
                </StyledRow>
              </div>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginTop: "20px", textAlign: "end" }}>
        <Button
          sx={{
            fontSize: "14px",
            fontWeight: "600",
            padding: "8px 20px",
            backgroundColor: "#FFC107",
            borderRadius: "8px",
            textTransform: "capitalize",
            fontFamily: "Poppins",
          }}
          variant="contained"
          onClick={handleBuyLead}
        >
          Buy
        </Button>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {currentPage > 1 && (
          <Button onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </Button>
        )}
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        {currentPage < totalPages && (
          <div>
            <Button onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default IntentBodyUser;
