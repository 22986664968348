import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import greyuser from "../../assets/images/usre-grey.jpg"

// Styled Card component
const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  borderRadius: "18px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  position: "relative",
}));

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: "10px",
}));

const StyledField = styled(Typography)(({ theme }) => ({
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
  fontSize: "14px",
}));

const CartIconWrapper = styled("div")(() => ({
  position: "fixed",
  top: "80px",
  right: "20px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  color: "#0A58CA",
  fontWeight: "600",
  fontSize: "16px",
  background: "#fff",
  padding: "8px 12px",
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 1000,
}));
const maskData = (data) => {
  return data?.length > 1 ? data[0] + "*".repeat(data?.length - 1) : data;
};

const IntentBody = () => {
  const user = useSelector((state) => state.userReducer);
  const [datas, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [totalPages, setTotalPages] = useState(1); // Total number of pages
  const [loading, setLoading] = useState(true);
  const [revealedLeadIds, setRevealedLeadIds] = useState(new Set());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page = 1) => {
    try {
      const response = await axios.get(`/intent?page=${page}`);
      const updatedData = response.data?.intent_list.map((item) => ({
        ...item,
        price: 1,
      }));
      setData(updatedData);
      setTotalPages(response.data.totalPages); // Set the total pages for pagination
      setCurrentPage(response.data.currentPage); // Update the current page
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteIntent = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this intent?"
    );

    // Proceed only if the user confirms
    if (isConfirmed) {
      try {
        const response = await axios.delete(`/intent/${id}`);
        alert(response.data.message); // Show success message
        fetchData(currentPage); // Refresh the data after deleting
      } catch (error) {
        toast.error(error.response?.data?.message || "An error occurred");
      }
    } else {
      // User canceled the deletion
      console.log("Deletion canceled");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Fetch data on page change

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update current page on pagination button click
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Grid
        container
        spacing={3}
        style={{ boxSizing: "border-box", marginBottom: "1rem" }}
      >
        {/* Cart Icon and Count */}

        {datas.map((data) => (
          <Grid item xs={12} sm={6} md={4} key={data._id}>
            <StyledCard>
              {/* Header */}
              <StyledRow>
                <img
                  src={
                    data?.logo
                      ? `${process.env.REACT_APP_API_URL}/${data.logo}`
                      : `${greyuser}`
                  }
                  alt={data?.companyName || "No Company Name"}
                  style={{ width: "50px", height: "50px", borderRadius: "5px" }}
                />
                <StyledField variant="h6">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {" "}
                    <div
                      style={{
                        color: "#0A58CA",
                        fontFamily: "Poppins",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {data?.category || " "}
                    </div>
                    <div> {data?.companyName || "No Company Name"}</div>{" "}
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 1: Name & Designation */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Name</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.customerName
                        : maskData(data?.customerName) || "No Customer Name"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> Designation</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.designation
                        : maskData(data?.designation) || "No Designation"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 2: Mobile & Email */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Mobile</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.mobileNumber
                        : maskData(data?.mobileNumber) || "No Mobile"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>Email</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.email
                        : maskData(data?.email) || "No Email"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>

              {/* Row 3: City & State */}
              <StyledRow>
                <StyledField>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div> City</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.city
                        : maskData(data?.city) || "No City"}
                    </div>
                  </div>
                </StyledField>
                <StyledField>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div> State</div>
                    <div>
                      {" "}
                      {revealedLeadIds.has(data._id)
                        ? data?.state
                        : maskData(data?.state) || "No State"}
                    </div>
                  </div>
                </StyledField>
              </StyledRow>
              {user.department === "Superadmin" &&
                user.username === "admin" && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteIntent(data._id)}
                  >
                    Delete
                  </Button>
                )}
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {currentPage > 1 && (
          <Button onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </Button>
        )}
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        {currentPage < totalPages && (
          <div>
            <Button onClick={() => handlePageChange(currentPage + 1)}>
              Next
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default IntentBody;
