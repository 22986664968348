import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import greyuser from "../../../../assets/images/usre-grey.jpg"


// Styled Card component
const StyledCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  borderRadius: "18px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
}));

const maskData = (data) => {
  return data?.length > 1 ? data[0] + "*".repeat(data?.length - 1) : data;
};

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: theme.spacing(2),
  marginBottom: "10px",
}));

const StyledField = styled(Typography)(({ theme }) => ({
  flex: 1,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textAlign: "left",
  fontSize: "14px",
}));

const IntentSample = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_PULISHABLE_KEY);

  const [datas, setData] = useState([]);
  const [paymentCheckUser, setPayementCheckUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [revealedLeadIds, setRevealedLeadIds] = useState(new Set());
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    fetchData();
    fetchCheckFirstPayment();
  }, []);

  const handleAddToCart = async () => {
    try {
      await axios.post(`/users/${user._id}/addToCart`, {
        id: user._id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get("/intent");
      setData(response.data?.intent_list);
      console.log(response.data?.intent_list);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const payment = async (leadId) => {
    try {
      const stripe = await stripePromise;
      const paymentResponse = await axios.post(
        "/payment",
        {
          campaignData: paymentCheckUser,
          userId: paymentCheckUser._id,
          from: "bantLead",
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY}`,
          },
        }
      );
      const sessionId = paymentResponse.data.id;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCheckFirstPayment = async () => {
    try {
      const response = await axios.get(`/users/${user._id}`);
      setPayementCheckUser(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBuyLead = (leadId) => {
    if (paymentCheckUser.isFirstCampaignPaid) {
      setRevealedLeadIds((prev) => new Set(prev).add(leadId));
    } else {
      payment(leadId);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Grid
      container
      spacing={3}
      style={{ boxSizing: "border-box", marginBottom: "1rem" }}
    >
      {datas.map((data) => (
        <Grid item xs={12} sm={6} md={4} key={data?.id}>
          <StyledCard>
            {/* Header */}
            <StyledRow>
              <img
                src={
                  data?.logo
                    ? `${process.env.REACT_APP_API_URL}/${data.logo}`
                    : `${greyuser}`
                }
                alt={data?.companyName || "No Company Name"}
                style={{ width: "50px", height: "50px", borderRadius: "5px" }}
              />
              <StyledField variant="h6">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <div
                    style={{
                      color: "#0A58CA",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                    }}
                  >
                    {" "}
                    {data?.category || " "}
                  </div>
                  <div> {data?.companyName || "No Company Name"}</div>{" "}
                </div>
              </StyledField>
            </StyledRow>

            {/* Row 1: Name & Designation */}
            <StyledRow>
              <StyledField>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Name</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.customerName
                      : maskData(data?.customerName) || "No Customer Name"}
                  </div>
                </div>
              </StyledField>
              <StyledField>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div> Designation</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.designation
                      : maskData(data?.designation) || "No Designation"}
                  </div>
                </div>
              </StyledField>
            </StyledRow>

            {/* Row 2: Mobile & Email */}
            <StyledRow>
              <StyledField>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Mobile</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.mobileNumber
                      : maskData(data?.mobileNumber) || "No Mobile"}
                  </div>
                </div>
              </StyledField>
              <StyledField>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>Email</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.email
                      : maskData(data?.email) || "No Email"}
                  </div>
                </div>
              </StyledField>
            </StyledRow>

            {/* Row 3: City & State */}
            <StyledRow>
              <StyledField>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div> City</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.city
                      : maskData(data?.city) || "No City"}
                  </div>
                </div>
              </StyledField>
              <StyledField>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <div> State</div>
                  <div>
                    {" "}
                    {revealedLeadIds.has(data.id)
                      ? data?.state
                      : maskData(data?.state) || "No State"}
                  </div>
                </div>
              </StyledField>
            </StyledRow>

            {/* Buttons */}
            <div style={{ paddingTop: "1rem" }}>
              <StyledRow>
                <Button
                  onClick={() => handleAddToCart(data.id)}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    padding: "6px 18px",
                    backgroundColor: "#0A58CA",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                  }}
                  variant="contained"
                >
                  Add to cart
                </Button>
                <Button
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    padding: "6px 18px",
                    backgroundColor: "#55B56D",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "#46a35e", // Change to your desired hover color
                    },
                  }}
                  variant="contained"
                  onClick={() => handleBuyLead(data.id)}
                >
                  Buy Lead
                </Button>
                <Button
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    padding: "6px 18px",
                    backgroundColor: "#55B56D",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    fontFamily: "Poppins",
                    "&:hover": {
                      backgroundColor: "#46a35e", // Change to your desired hover color
                    },
                  }}
                  variant="contained"
                >
                  More
                </Button>
              </StyledRow>
            </div>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default IntentSample;
