import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { saveMIntents } from "../../services/mintentService";
import { useNavigate } from "react-router-dom";

const IntentForm = () => {
  const user = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const initialData = {
    customerName: "",
    companyName: "",
    designation: "",
    mobileNumber: "",
    email: "",
    city: "",
    state: "",
    leadComments: "",
    category: "",
    user_id: user?._id || "",
  };

  const [data, setData] = useState(initialData);
  const [logo, setLogo] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const doSubmit = async (event) => {
    event.preventDefault();


    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        if (data[key]) {
          formData.append(key, data[key]);
        }
      });
      if (logo) formData.append("logo", logo);

      const response = await saveMIntents(formData);

      toast.success("Form submitted successfully!");
      navigate("/admin_panel/intent");
    } catch (ex) {
   
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message || "Something went wrong.");
      }
    }
  };

  const renderInput = (name, label, type = "text", placeholder = "") => (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type={type}
        id={name}
        name={name}
        value={data[name] || ""}
        onChange={handleChange}
        placeholder={placeholder}
        className="form-control"
      />
    </div>
  );

  const renderFileInput = (name, label) => (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <input
        type="file"
        id={name}
        name={name}
        onChange={handleFileChange}
        className="form-control"
      />
      {logo && (
        <img
          src={URL.createObjectURL(logo)}
          alt="Preview"
          style={{ width: "100px", height: "100px", marginTop: "10px" }}
        />
      )}
    </div>
  );

  return (
    <div className="intent-form-container">
      <h2>Intent Form Add intet Data</h2>
      <form onSubmit={doSubmit}>
        {renderInput("category", "Category")}
        {renderInput("companyName", "Company Name")}
        {renderInput("customerName", "Customer Name")}
        {renderInput("designation", "Designation")}
        {renderInput("mobileNumber", "Mobile Number")}
        {renderInput("email", "Email")}
        {renderInput("city", "City")}
        {renderInput("state", "State")}
        {renderInput("leadComments", "Lead Comments")}
        {renderFileInput("logo", "Logo")}
        <div className="form-actions">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default IntentForm;

