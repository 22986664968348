import React from "react";
import {
  FaThLarge,
  FaUser,
  FaUsers,
  FaMousePointer,
  FaBullhorn,
  FaPlus,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { FcMoneyTransfer } from "react-icons/fc";

export const MenuItems = () => {
  const user = useSelector((state) => state.userReducer);

  return [
    {
      key: "/user_dashboard",
      icon: <FaThLarge />, // Use react-icons for Dashboard
      text: "Dashboard",
    },
    {
      key: "myCampaigns",
      icon: <FaBullhorn />, // Use react-icons for My Campaigns
      text: "My Campaigns",
    },
    {
      key: user ? `/user_dashboard/Campaigns/${user._id}/new` : "/login", // Ensure user exists
      icon: <FaPlus />, // Use react-icons for New Campaign
      text: "New Campaign",
    },
    {
      key: "intent",
      icon: <FaMousePointer />, // Use react-icons for Intent Data
      text: "Active BANT Leads",
    },
    {
      key: "PurchasedLeads",
      icon: <FcMoneyTransfer />, // Use react-icons for Active Leads
      text: "PurchasedLeads",
    },
    {
      key: "activeLeads",
      icon: <FaUsers />, // Use react-icons for Active Leads
      text: "Intent Data",
    },
  ];
};

export const MenuItemsSeller = [
  {
    key: "/user_dashboard",
    icon: <FaThLarge />, // Use react-icons for Dashboard
    text: "Dashboard",
  },
  {
    key: "myCampaigns",
    icon: <FaBullhorn />, // Use react-icons for My Campaigns
    text: "My Campaigns",
  },
  {
    key: "newCampaign",
    icon: <FaPlus />, // Use react-icons for New Campaign
    text: "New Campaign",
  },
  {
    key: "billing",
    icon: <FaMousePointer />, // Use react-icons for Billing
    text: "Billing",
  },
];
