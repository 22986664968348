import React, { useState, useEffect } from "react";
import Filter from "./Filter";

import Header from "./Header";
import { useLocation, useParams } from "react-router-dom";
import { paginate } from "../../utils/paginate";
import Pagination from "../../common/pagination";
import { toast } from "react-toastify";
import SearchBox from "../../common/searchBox";
import PageSize from "../../common/pageSize";
import ActiveCXOLeedsTable from "./ActiveCXOLeedsTable";
import { useSelector } from "react-redux";
import {
  deleteMIntentApply,
  getUserMIntentApply,
  getIntentMIntentApply,
  statusMIntentApply,
} from "../../services/mintentApplyService";
import axios from "axios";

function ActiveCXOLeedsList() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const location = useLocation();
  const params = useParams();
  const user = useSelector((state) => state.userReducer);

  const fetchData = async () => {
    try {
      const response = await axios.get("/mintentApply");
      console.log(response.data);
      setItems(response.data.intent_list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (location) fetchData();
  }, [location]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleDelete = async (item) => {
    const originalDdata = items;
    const data = originalDdata.filter((u) => u._id !== item._id);
    setItems(data);
    setCurrentPage(1);
    try {
      const response = await deleteMIntentApply(item._id);
      toast.success(response.data.message);
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handleStatus = async (mintent) => {
    console.log("mintent", mintent);
    try {
      const response = await statusMIntentApply(mintent._id, mintent);
      toast.success(response.data.message);
      fetchData();
      setCurrentPage(1);
    } catch (ex) {
      if (ex.response && ex.response.status >= 400) {
        toast.error(ex.response.data.message);
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPagedData = () => {
    let filtered = items;
    if (searchQuery)
      filtered = items.filter((m) =>
        m.name.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
    const item = paginate(filtered, currentPage, pageSize);
    return { totalCount: filtered.length, data: item };
  };
  const { length: count } = items;

  useEffect(() => {
    if (user && user.status === "New") {
      if (items.length > 1) {
        const trElements = document
          .getElementsByClassName("active-lead-table")[0]
          .querySelectorAll("table tr:not(:first-child)");

        for (let i = 0; i < trElements.length; i++) {
          trElements[i].style.setProperty("opacity", "0.2");
        }
      }
    }
  }, [items, user]);

  if (count === 0) return <p>There are no data in the database.</p>;

  const { totalCount, data: item } = getPagedData();

  return (
    <>
      {/* <Filter searchQuery={searchQuery} onSearch={handleSearch} /> */}
      {/* <Header /> */}
      <div className="active-lead-table">
        <ActiveCXOLeedsTable
          items={item}
          onDelete={handleDelete}
          onStatus={handleStatus}
        />
      </div>
      <Pagination
        itemsCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </>
  );
}

export default ActiveCXOLeedsList;
