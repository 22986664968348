import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";

import { FaCommentDots } from "react-icons/fa";

const PurchasedLeads = () => {
  const [purchasedLeads, setPurchasedLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page (1-based)
  const [rowsPerPage] = useState(10); // Fixed rows per page
  const [totalLeads, setTotalLeads] = useState(0); // Total number of leads
  const [totalPages, setTotalPages] = useState(0); // Total pages for pagination

  const user = useSelector((state) => state.userReducer);
  const userId = user?._id; // Safely access userId

  const fetchPurchasedLeads = async (page) => {
    try {
      setLoading(true);
      const response = await axios.post(`/payment/getPurchasedLeads`, {
        userId: userId, // Pass userId in the request body
        page, // Current page (1-based)
        limit: rowsPerPage, // Number of rows per page
      });

      setPurchasedLeads(response.data.leads || []);
      setTotalLeads(response.data.totalLeads || 0);
      setTotalPages(response.data.totalPages || 0);
    } catch (err) {
      console.error("Error fetching purchased leads:", err);
      setError(err.response?.data?.error || "Failed to fetch purchased leads");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchPurchasedLeads(currentPage);
    }
  }, [userId, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <Typography color="error" align="center" variant="h6">
        {error}
      </Typography>
    );
  }

  return (
    <div style={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Purchased Leads
      </Typography>
      {purchasedLeads.length === 0 ? (
        <Typography>No leads found.</Typography>
      ) : (
        <>
          <TableContainer component={Paper} style={{ borderRadius: "10px" }}>
            <Table>
            <TableHead>
  <TableRow>
    <TableCell><strong>Serial Number</strong></TableCell> {/* Change from Lead ID to Serial Number */}
    <TableCell><strong>Company Name</strong></TableCell>
    <TableCell><strong>Customer Name</strong></TableCell>
    <TableCell><strong>Email</strong></TableCell>
    <TableCell><strong>Phone</strong></TableCell>
    <TableCell><strong>City</strong></TableCell>
    <TableCell><strong>State</strong></TableCell>
    <TableCell><strong>Designation</strong></TableCell>
    <TableCell><strong>Requirement</strong></TableCell>
  </TableRow>
</TableHead>

<TableBody>
  {purchasedLeads.map((lead, index) => (
    <TableRow key={lead._id}>
      <TableCell>{index + 1}</TableCell> {/* Generate Serial Number based on index */}
      <TableCell>{lead.companyName || "N/A"}</TableCell>
      <TableCell>{lead.customerName || "N/A"}</TableCell>
      <TableCell>{lead.email || "N/A"}</TableCell>
      <TableCell>{lead.mobileNumber || "N/A"}</TableCell>
      <TableCell>{lead.city || "N/A"}</TableCell>
      <TableCell>{lead.state || "N/A"}</TableCell>
      <TableCell>{lead.designation || "N/A"}</TableCell>

      <TableCell>
        {lead.leadComments ? (
          <Tooltip title={lead.leadComments}>
            <IconButton color="primary">
              <FaCommentDots />
            </IconButton>
          </Tooltip>
        ) : (
          "N/A"
        )}
      </TableCell>
    </TableRow>
  ))}
</TableBody>

            </Table>
          </TableContainer>

          {/* Pagination Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {currentPage > 1 && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </Button>
            )}
            <span style={{ fontSize: "14px", color: "#555" }}>
              {`Page ${currentPage} of ${totalPages}`}
            </span>
            {currentPage < totalPages && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PurchasedLeads;
