import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { Typography, Box, CircularProgress } from "@mui/material";

const BuyLeadSuccessfulPayment = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer); // Get user from Redux store

  useEffect(() => {
    // Function to be run after payment
    const handleAfterPayment = async () => {
      console.log("user", user);

      // Add your post-payment logic here
      try {
        const confirmResponse = await axios.post(
          "/payment/confirmLeadPurchase",
          {
            userId: user._id,
          }
        );
        console.log("Confirm response:", confirmResponse.data);
      } catch (error) {
        console.error("Error in confirmLeadPurchase:", error);
      }
    };

    // Run the function
    handleAfterPayment();

    // Timer for redirection
    const timer = setTimeout(() => {
      navigate("/user_dashboard/PurchasedLeads"); // Redirect to desired route
    }, 3000); // 5 seconds

    // Clear timer if component unmounts
    return () => clearTimeout(timer);
  }, [navigate, user]); // Include `user` in dependency array

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
      padding={3}
      bgcolor="#e0f7fa"
      borderRadius={2}
    >
      <CircularProgress style={{ marginBottom: "20px" }} />
      <Typography variant="h4" gutterBottom color="success.main">
        Payment Successful!
      </Typography>
      <Typography variant="body1" color="textSecondary">
        You will be redirected in 3 seconds...
      </Typography>
    </Box>
  );
};

export default BuyLeadSuccessfulPayment;