import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { IntentBodyUser } from './../../../../tables/'

const MIntent = () => {
  return (
    <>
      {/* <div class='campaign-top d-flex'>
        <div class='camping-hd'>
          <h4>Intent Data</h4>
          <p>Comprehensive record of all the inetnt data available</p>
        </div>
        <Link to='new' class='btn btn-prfledt'>
          <i class='fa fa-plus' aria-hidden='true'></i>
        </Link>
      </div> */}

      <IntentBodyUser />
    </>
  )
}

export default MIntent
